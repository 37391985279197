import JustValidate, {Rules} from 'just-validate';
import {isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js';
import {validatePolish} from 'validate-polish';
import {Modal} from 'bootstrap';

const vinbox = document.getElementById('input_vin');


const FormErrorDictionary = {
  Required: "Pole wymagane",
  Email: "email",
  MinLength: "minLength",
  Integer: 'Oczekiwano liczby',
  // MaxLength = "maxLength",
  // Password = "password",
  // Number = "number",
  // MaxNumber = "maxNumber",
  // MinNumber = "minNumber",
  // StrongPassword = "strongPassword",
  // CustomRegexp = "customRegexp",
  // MinFilesCount = "minFilesCount",
  // MaxFilesCount = "maxFilesCount",
  // Files = "files"
}
const formPrefix = '#form_signup';
const form = document.querySelector(formPrefix);
const smsForm = document.querySelector('form#form_check');
const smsUuidInput = document.querySelector('#form_check_uuid');
if (form) {
  let smsConfirm = new Modal(document.getElementById('sms_confirm'), {});

  const validationSms = new JustValidate(smsForm, {
    focusInvalidField: true,
    validateBeforeSubmitting: true,
    errorFieldStyle: '',
    errorFieldCssClass: ['is-invalid'],
    errorLabelStyle: '',
    errorLabelCssClass: ['invalid-feedback', 'd-block'],
  });

  validationSms
      .addField('#form_check_smscode', [
        {
          rule: 'required',
          errorMessage: FormErrorDictionary.Required,
        },
        {
          rule: Rules.Integer,
          errorMessage: FormErrorDictionary.Integer,
        },
        {
          rule: 'minLength',
          value: 6,
          errorMessage: 'Kod powinien mieć dokładnie 6 znaków.',
        },
        {
          rule: 'maxLength',
          value: 6,
          errorMessage: 'Kod powinien mieć dokładnie 6 znaków.',
        },
      ])
      .onValidate(event => {
        if (event.isValid) {
          smsForm.querySelector('button').classList.remove('disabled');
        } else {
          smsForm.querySelector('button').classList.add('disabled');
        }
      })
      .onSuccess((event) => {
        let formData = new FormData(smsForm);
        postRegisterData(formData).then(data => {
          if (!data.success) {
            validationSms.showErrors({
              '#form_check_smscode': data.error,
            });
          } else {
            smsConfirm.hide();
            document.getElementById('form_wrapper').classList.add('d-none');
            document.getElementById('thankyou').classList.remove('d-none');
          }
        }).catch(e => {
          alert('Wystąpił nieznany problem. Spróbuj ponownie później.');
        })
      });

  const validation = new JustValidate(formPrefix, {
    focusInvalidField: true,
    validateBeforeSubmitting: true,
    errorFieldStyle: '',
    errorFieldCssClass: ['is-invalid'],
    errorLabelStyle: '',
    errorLabelCssClass: ['invalid-feedback', 'd-block'],
  });

  let firstnameInput = form.querySelector(formPrefix + '_firstname');
  let lastnameInput = form.querySelector(formPrefix + '_lastname');
  let emailInput = form.querySelector(formPrefix + '_email');
  let emailrepInput = form.querySelector(formPrefix + '_emailrep');
  let phoneInput = form.querySelector(formPrefix + '_phone');
  let peselInput = form.querySelector(formPrefix + '_pesel');

  //
  // let children = [... form.getElementsByTagName('input')];
  // children.forEach(el => {
  //     el.addEventListener('input', e => {
  //         validation.revalidateField(e.target);
  //     });
  // });

  let smsSubmitBtn = document.getElementById('form_check_submit');
  smsSubmitBtn.addEventListener('click', e => {
    if (smsSubmitBtn.classList.contains('disabled')) {
      validationSms.validate(true);
    }
    e.stopPropagation();
    return false;
  });

  document.addEventListener('click', async (e) => {
    // Make sure, that you're receiving event from a correct element
    if (e.target.id === 'generate-new-sms') {
      e.stopPropagation();
      e.preventDefault();

      document.getElementById('form_check_smscode').value = '';
      validationSms.clearErrors();
      const response = fetch('/api/resend-sms', {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uuid: smsUuidInput.value,
        })
      });
      let resp = await response.json();

      console.log(resp);
    }
  });

  let submitBtn = document.getElementById('form_signup_submit');

  submitBtn.addEventListener('click', e => {
    if (submitBtn.classList.contains('disabled')) {
      validation.validate(true);
    }
    e.stopPropagation();
    return false;
  });


  function handleRadioClick() {
    if (document.getElementById('posiada_tak').checked) {
      vinbox.style.display = 'block';
      validation
          .addField(formPrefix + '_vin', [
            {
              rule: 'required',
              errorMessage: FormErrorDictionary.Required,
            },
            {
              rule: 'minLength',
              value: 17,
              errorMessage: 'VIN powinien mieć dokładnie 17 znaków.',
            },
            {
              rule: 'maxLength',
              value: 17,
              errorMessage: 'VIN powinien mieć dokładnie 17 znaków.',
            },
          ])
    } else {
      vinbox.style.display = 'none';
      validation.removeField(formPrefix + '_vin');
    }
  }

  const radioButtons = document.querySelectorAll('input[name="form_signup[has_car]"]');
  radioButtons.forEach(radio => {
    radio.addEventListener('click', handleRadioClick);
  });

  validation
      .addField(firstnameInput, [
        {
          rule: 'required',
          errorMessage: FormErrorDictionary.Required,
        },
        {
          rule: 'minLength',
          value: 3,
          errorMessage: 'Imię musi mieć minimum 3 znaki',
        },
        {
          rule: 'maxLength',
          value: 30,
          errorMessage: 'Imię może mieć maksymalnie 30 znaków',
        },
      ])

      .addField(lastnameInput, [
        {
          rule: 'required',
          errorMessage: FormErrorDictionary.Required,
        },
        {
          rule: 'minLength',
          value: 3,
          errorMessage: 'Nazwisko musi mieć minimum 3 znaki',
        },
        {
          rule: 'maxLength',
          value: 30,
          errorMessage: 'Nazwisko może mieć maksymalnie 30 znaków',
        },
      ])
      .addField(emailInput, [
        {
          rule: 'required',
          errorMessage: FormErrorDictionary.Required,
        },
        {
          rule: 'email',
          errorMessage: 'Niepoprawny adres e-mail',
        },
      ])
      .addField(emailrepInput, [
        {
          rule: 'required',
          errorMessage: FormErrorDictionary.Required,
        },
        {
          rule: 'email',
          errorMessage: 'Niepoprawny adres e-mail',
        },
        {
          validator: (value, fields) => {
            return emailInput.value === value;
          },
          errorMessage: 'Wpisana wartość jest niezgodna z polem Adres e-mail',
        },
      ])
      .addField(peselInput, [
        {
          rule: 'required',
          errorMessage: FormErrorDictionary.Required,
        },
        {
          rule: 'minLength',
          value: 11,
          errorMessage: 'PESEL powinien mieć dokładnie 11 znaków.',
        },
        {
          rule: 'maxLength',
          value: 11,
          errorMessage: 'PESEL powinien mieć dokładnie 11 znaków.',
        },
        {
          validator: (value) => {
            return validatePolish.pesel(value);
          },
          errorMessage: 'Niepoprawny numer pesel',
        },
      ])
      .addRequiredGroup('#radio_group_has_car', 'Wybierz jedną z opcji')
      .addField(
          formPrefix + '_przetwarzanie',
          [
            {
              rule: 'required',
              errorMessage: FormErrorDictionary.Required,
            },
          ],
      )
      .addField(
          formPrefix + '_legal',
          [
            {
              rule: 'required',
              errorMessage: FormErrorDictionary.Required,
            },
          ],
      )
      .addField(phoneInput, [
        {
          rule: 'required',
          errorMessage: FormErrorDictionary.Required,
        },
        {
          validator: (value) => {
            if (value && value.length >= 9 && isValidPhoneNumber(value, 'PL')) {
              let res = parsePhoneNumber(value, 'PL');
              return res.country === 'PL';
            }

            return false;
          },
          errorMessage: 'Niepoprawny numer telefonu',
        },
      ])
      .onValidate(event => {
        if (event.isValid) {
          form.querySelector('button').classList.remove('disabled');
        } else {
          form.querySelector('button').classList.add('disabled');
        }
      })
      .onSuccess((event) => {
        let formData = new FormData(form);
        postValidateData(formData).then(data => {
          turnstile.reset();
          if (!data.success) {
            alert(data.error);
          } else {
            smsUuidInput.value = data.uuid;
            smsConfirm.show();


            // document.getElementById('form_wrapper').classList.add('d-none');
            // document.getElementById('thankyou').classList.remove('d-none');
          }
        }).catch(e => {
          alert('Wystąpił nieznany problem. Spróbuj ponownie później.');
        })
      });

  document.querySelectorAll('.is-invalid').forEach(function (el) {
    validation.errorLabels['#' + el.id] = el.nextElementSibling;
  });


  async function postValidateData(formData) {
    const response = await fetch('/api/validate', {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Object.fromEntries(formData))
    });
    return response.json();
  }


  async function postRegisterData(formData) {
    const response = await fetch('/api/register', {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Object.fromEntries(formData))
    });
    return response.json();
  }
}
